<template>
    <div class="balance-block p-2 p-md-2 p-lg-3" :class="'balance-block-' + colour">
        <div class="mb-2 mb-md-3 label">{{ label }}</div>
        <div class="value">{{ this.$filters.formatMoney(this.value) }}</div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                required: true,
            },
            value: {
                required: true,
            },
            colour: {
                required: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    .balance-block
    {
        background: #EEEEEE;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .balance-block-green
    {
        background-color: lighten($swift-fresh-green, 12);
    }

    .balance-block-yellow
    {
        background-color: lighten($swift-yellow, 12);
    }

    .label
    {
        font-weight: bold;
        line-height: 1.2;
    }

    .value
    {
        font-size: 1.2em;
        font-weight: bold;
        line-height: 1;
    }
</style>