<template>
    <div v-if="statementData">
        <div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h1 class="h4 mb-0">SwiftPlus Summary - {{ this.$filters.formatDateMonthYear(this.statementData.month) }}</h1>
                </div>
                <div>
                    <span class="btn btn-sm btn-secondary ms-1" @click="openStatementPdf">Print</span>
                </div>
            </div>
        </div>
        
        <PageSection>
            <PageSectionHeading>Redeemable</PageSectionHeading>
            <div class="d-block d-md-flex flex-nowrap">
                <SwiftPlusBalanceBlock label="Opening Balance" :value="statementData.summary.redeemable.opening_balance" colour="grey" />
                <div class="calculation-symbol px-2 py-1">+</div>
                <SwiftPlusBalanceBlock label="Invoices" :value="statementData.summary.redeemable.invoices" colour="yellow" />
                <div class="calculation-symbol px-2 py-1">-</div>
                <SwiftPlusBalanceBlock label="Contract Payments" :value="statementData.summary.redeemable.contract_payment" colour="green" />
                <div class="calculation-symbol px-2 py-1">-</div>
                <SwiftPlusBalanceBlock label="Payments & Credits" :value="statementData.summary.redeemable.variable_payment" colour="green" />
                <div class="calculation-symbol px-2 py-1">=</div>
                <SwiftPlusBalanceBlock label="Closing Balance" :value="statementData.summary.redeemable.closing_balance" colour="grey" />
            </div>
        </PageSection>
        
        <PageSection>
            <PageSectionHeading>Non-Redeemable</PageSectionHeading>
            <div class="d-block d-md-flex flex-nowrap">
                <SwiftPlusBalanceBlock label="Opening Balance" :value="statementData.summary.non_redeemable.opening_balance" colour="grey" />
                <div class="calculation-symbol px-2 py-1">+</div>
                <SwiftPlusBalanceBlock label="Invoices" :value="statementData.summary.non_redeemable.invoices" colour="yellow" />
                <div class="calculation-symbol px-2 py-1">-</div>
                <SwiftPlusBalanceBlock label="Payments & Credits" :value="statementData.summary.non_redeemable.variable_payment" colour="green" />
                <div class="calculation-symbol px-2 py-1">=</div>
                <SwiftPlusBalanceBlock label="Closing Balance" :value="statementData.summary.non_redeemable.closing_balance" colour="grey" />
            </div>
        </PageSection>

        <PageSection>
            <PageSectionHeading>Payable</PageSectionHeading>
            <div class="total-payable d-md-flex justify-content-between">
                <div class="label">Total payable before {{ this.$filters.formatDateVerbose(statementData.summary.due_date) }}</div>
                <div class="value">{{ this.$filters.formatMoney(statementData.summary.total) }}</div>
            </div>
        </PageSection>

        <PageSection v-if="Object.keys(this.statementData.practice_totals).length > 1">
            <PageSectionHeading>Practice Invoice Summary</PageSectionHeading>
            <table class="table align-middle">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Redeemable</th>
                        <th>Non-Reddeemable</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="practice in statementData.practice_totals" v-bind:key="practice.id">
                        <td>{{ practice.name }}</td>
                        <td>{{ this.$filters.formatMoney(practice.total_redeemable) }}</td>
                        <td>{{ this.$filters.formatMoney(practice.total_nonredeemable) }}</td>
                        <td>{{ this.$filters.formatMoney(practice.total) }}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceRedeemableTotal) }}</strong></td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceNonRedeemableTotal) }}</strong></td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceTotal) }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </PageSection>

        <PageSection v-for="practice in statementData.practice_totals" v-bind:key="practice.id">
            <!-- <PageSectionHeading>Breakdown by Dentist</PageSectionHeading> -->
            <h6>{{ practice.name }}</h6>
            <table class="table align-middle">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Redeemable</th>
                        <th>Non-Redeemable</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(accountBreakDown, index) in statementData.breakdown_by_account" v-bind:key="index">
                        <td v-if="practice.id == accountBreakDown.practice_id">{{ statementData.accounts[index.replace('acc_', '')].dentist_name }}</td>
                        <td v-if="practice.id == accountBreakDown.practice_id">{{ this.$filters.formatMoney(accountBreakDown.redeemable_invoices) }}</td>
                        <td v-if="practice.id == accountBreakDown.practice_id">{{ this.$filters.formatMoney(accountBreakDown.nonredeemable_invoices) }}</td>
                        <td v-if="practice.id == accountBreakDown.practice_id">{{ this.$filters.formatMoney(parseFloat(accountBreakDown.redeemable_invoices) + parseFloat(accountBreakDown.nonredeemable_invoices)) }}</td>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceRedeemableTotals[practice.id]) }}</strong></td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceNonRedeemableTotals[practice.id]) }}</strong></td>
                        <td><strong>{{ this.$filters.formatMoney(this.practiceRedeemableTotals[practice.id] + this.practiceNonRedeemableTotals[practice.id]) }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </PageSection>

        <PageSection isLastSection="true">
            <!-- <PageSectionHeading>Invoices</PageSectionHeading> -->
            <span v-for="account of statementData.invoice_items" v-bind:key="account.id">
            <h6><span v-if="Object.keys(this.statementData.practice_totals).length > 1">{{ account.items[0].invoice.practice.name }} - </span>{{ account.items[0].invoice.dentist.first_name }} {{ account.items[0].invoice.dentist.surname }} - Invoice Details</h6>
            <table class="table align-middle">
                <thead>
                    <tr>
                        <th>Invoice</th>
                        <th>Patient</th>
                        <th class="d-none d-lg-table-cell column-shrink">Date</th>
                        <th class="d-none d-sm-table-cell">Product</th>
                        <th class="column-shrink column-shrink">Rdm.</th>
                        <th class="column-shrink column-shrink">Non-Rdm.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invoiceItem of account.items" v-bind:key="invoiceItem.id">
                        <td>
                            <strong>{{ invoiceItem.invoice.id }}</strong>
                        </td>
                        <td>{{ invoiceItem.invoice.patient_name }}</td>
                        <td class="d-none d-lg-table-cell">{{ this.$filters.formatDate(invoiceItem.invoice.date) }}</td>
                        <td class="d-none d-sm-table-cell">
                            <div class="product-name">{{invoiceItem.product.name}}</div>
                        </td>
                        <td class="column-number">{{ invoiceItem.redeemable_value ? this.$filters.formatMoney(invoiceItem.redeemable_value) : '-' }}</td>
                        <td class="column-number">{{ invoiceItem.nonredeemable_value ? this.$filters.formatMoney(invoiceItem.nonredeemable_value) : '-' }}</td>
                    </tr>
                    <tr>
                        <td class="d-xs-table-cell d-sm-none"></td>
                        <td colspan="2" class="d-none d-sm-table-cell d-lg-none"></td>
                        <td colspan="3" class="d-none d-lg-table-cell d-xxl-none"></td>
                        <td colspan="4" class="d-none d-xxl-table-cell"></td>
                        <td class="column-number"><strong>{{ this.$filters.formatMoney(account.redeemabletotal) }}</strong></td>
                        <td class="column-number"><strong>{{ this.$filters.formatMoney(account.nonredeemabletotal) }}</strong></td>
                    </tr>
                </tbody>
            </table>
            <br /><br />
            </span>
        </PageSection>
    </div>
</template>

<style scoped lang="scss">
    .product-name
    {
        max-width: 250px;
    }

    .calculation-symbol
    {
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.6em;
        line-height: 1;
    }

    .total-payable
    {
        .value
        {
            font-weight: bold;
        }
    }
</style>

<script>

    import api from "@/services/api";
    import base64 from "@/services/base64";
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';
    import SwiftPlusBalanceBlock from '@/components/SwiftPlusBalanceBlock.vue';

    export default {
        components: {
            PageSection,
            PageSectionHeading,
            SwiftPlusBalanceBlock,
        },
        data() {
            return {
                statementData: null,
            }
        },
        props: {
            month: {
                required: true,
            },
            year: {
                required: true,
            },
        },
        methods: {
            fetchStatementData: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.collectionData = null;
                    return;
                }

                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    date: this.year + '-' + this.month + '-01',
                };

                api
                .get('/api/v1/swift-plus/statement', {
                    params: params,
                })
                .then((response) => {
                    this.statementData = response.data;

                    this.calcPracticeTotals();
                    this.calcDentistTotals();
                })
                .catch(() => {
                    console.log('Catch statement error');
                });
            },
            calcPracticeTotals: function (){
                this.practiceTotal = 0;
                this.practiceRedeemableTotal = 0;
                this.practiceNonRedeemableTotal = 0;
                this.practiceRedeemableTotals = [];
                this.practiceNonRedeemableTotals = [];

                for (let [key, value] of Object.entries(this.statementData.practice_totals)) {
                    if (undefined === this.practiceRedeemableTotals[key]){
                        this.practiceRedeemableTotals[key] = 0;
                        this.practiceNonRedeemableTotals[key] = 0;
                    }

                    this.practiceTotal += value.total;
                    this.practiceRedeemableTotal += value.total_redeemable;
                    this.practiceNonRedeemableTotal += value.total_nonredeemable;
                    this.practiceRedeemableTotals[key] += value.total_redeemable;
                    this.practiceNonRedeemableTotals[key] += value.total_nonredeemable;
                }
            },
            calcDentistTotals: function (){
                this.dentistTotal = 0;
                this.dentistRedeemableTotal = 0;
                this.dentistNonRedeemableTotal = 0;
                for (let [key, value] of Object.entries(this.statementData.dentist_totals)) {
                    console.log(key);
                    this.dentistTotal += value.total;
                    this.dentistRedeemableTotal += value.total_redeemable;
                    this.dentistNonRedeemableTotal += value.total_nonredeemable;
                }
            },
            openStatementPdf: function() {
                var pdfWindow = window.open();
                pdfWindow.document.title = "Loading...";

                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    date: this.year + '-' + this.month + '-01',
                    outputFormat: 'base64',
                };

                api
                .get('/api/v1/swift-plus/statement-pdf', {
                    params: params,
                })
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch pdf error', error);
                    alert('There was an error downloading this statement, please try again later.');
                });
            },
        },
        mounted () {
            this.fetchStatementData();
        },
    }

</script>
